import React, { Fragment } from 'react';
import { Breadcrumbs } from '../../../../AbstractElements';
import ChatStatus from './ChatStatus';
import Chatting from './Chatting';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';

const ChatAppContain = () => {
    return (
        <Fragment>
            <Breadcrumbs mainTitle="Chat" parent="Apps" title="Chat" />
            <Container fluid={true}>
                <Row>
                    <Col className="call-chat-sidebar">
                        <Card>
                            <CardBody className="chat-body">
                                <ChatStatus />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col className="call-chat-body">
                        <Card>
                            <CardBody className="p-0">
                                <Chatting />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Fragment>
    );
};
export default ChatAppContain;