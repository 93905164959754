import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { Breadcrumbs } from "../../../AbstractElements";
import { useNavigate, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import { toast } from "react-toastify";
import { insertCvCategories, updateCvCategories } from "../../../api/api";

function CvCategories() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [imgFile, setImgFile] = useState("");

  const handleAddBlogs = async () => {
    if (!title) {
      toast.error("Please fill all the required fields.");
      return;
    }
    if (!imgFile) {
      toast.error("Please fill all the required fields.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("category_name", title);
    formData.append("category_image", imgFile);

    const res = await insertCvCategories(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/category/category_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const updateAddBlogs = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("category_name", title);
    formData.append("category_image", imgFile);

    const res = await updateCvCategories(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/category/category_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const populateData = () => {
    if (location.state) {
      setTitle(location.state.dataObj.category_name);
      setImgFile(location.state.dataObj.category_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    setImgFile(file);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Categories"
        title="Add Category"
        mainTitle="Add Category"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Name"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          placeholder="Enter Name"
                          type="text"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Image"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location.state ? (
                          <Button
                            onClick={() =>
                              updateAddBlogs(location.state.dataObj.category_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Updating...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAddBlogs}
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Submiting...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CvCategories;
