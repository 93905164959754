import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { getSkillsPageSection, updateSkillsPageSection } from "../../../api/api";

const CvSkillsPageSection = () => {
  const [title, setTitle] = useState("");
  const [texts, setTexts] = useState("");
  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getSkillsPageSection()
      .then((res) => {
        setTitle(res?.data[0]?.section_title);
        setTexts(res?.data[0]?.section_text);
        setIdState(res?.data[0].page_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onUpdatePage = async (e) => {
    e.preventDefault();

    const formdata = {
      section_title: title,
      section_text: texts,
    };

    await updateSkillsPageSection(idState, formdata);
    alert(`Updated!`);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Skills Page Section"
          title="Skills Page Section"
          mainTitle="Skills Page Section"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Title"}</Label>
                          <input
                            placeholder="Enter Title"
                            className="form-control"
                            type="text"
                            name="section_title"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Short Texts"}</Label>
                          <input
                            placeholder="Enter Text"
                            className="form-control"
                            name="section_text"
                            value={texts}
                            type="text"
                            onChange={(e) => setTexts(e.target.value)}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            type="submit"
                            onClick={onUpdatePage}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default CvSkillsPageSection;