import React from 'react';
import { AlertCircle, AlertTriangle, Bell, Heart, HelpCircle, ThumbsDown, ThumbsUp } from 'react-feather';

export const data = [
    {
        id: 2,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'secondary dark',
        btnclose: 'default'
    },
    {
        id: 3,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'success dark',
        btnclose: 'default'
    },
    {
        id: 4,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'info dark',
        btnclose: 'default'
    },
    {
        id: 5,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'warning dark',
        btnclose: 'default'
    },
    {
        id: 6,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'danger dark',
        btnclose: 'default'
    },
    {
        id: 7,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'light dark',
        btnclose: 'default'
    },
    {
        id: 8,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default'
    }
];

export const dataWithIcon = [
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary dark',
        btnclose: 'default',
        icon: <Heart />
    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success dark',
        btnclose: 'default',
        icon: <ThumbsUp />
    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info dark',
        btnclose: 'default',
        icon: <HelpCircle />
    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning dark',
        btnclose: 'default',
        icon: <Bell />
    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger dark',
        btnclose: 'default',
        icon: <ThumbsDown />
    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light dark',
        btnclose: 'default',
        icon: <AlertTriangle />
    },
    {
        id: 8,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default',
        icon: <AlertCircle />
    }
];

export const AlertWithLightDismissData = [
    {
        id: 2,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'secondary',
        btnclose: 'default'
    },
    {
        id: 3,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'success',
        btnclose: 'default'
    },
    {
        id: 4,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'info',
        btnclose: 'default'
    },
    {
        id: 5,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'warning',
        btnclose: 'default'
    },
    {
        id: 6,
        alerttxt: '<strong>Holy ! </strong> You can check in on some of those fields below.',
        alertcolor: 'danger',
        btnclose: 'default'
    },
    {
        id: 7,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'light',
        btnclose: 'default'


    },
    {
        id: 8,
        alerttxt: '<strong>Holy ! </strong>You can check in on some of those fields below.',
        alertcolor: 'dark',
        btnclose: 'default'
    }
];

export const IconInverseData = [
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary inverse',
        btnclose: 'default',
        icon: <i className="icon-heart"></i>


    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success inverse',
        btnclose: 'default',
        icon: <i className="icon-thumb-up alert-center"></i>,
        boldtxt: 'Well Done!'


    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info inverse',
        btnclose: 'default',
        icon: <i className="icon-help-alt"></i>,
        boldtxt: 'Welcome!'
    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning inverse',
        btnclose: 'default',
        icon: <i className="icon-bell"></i>,
        boldtxt: 'Congratulation!'

    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger inverse',
        btnclose: 'default',
        icon: <i className="icon-thumb-down"></i>,
        txt: 'Your',
        boldtxt: 'Perfomance'



    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light outline inverse',
        btnclose: 'default',
        icon: <i className="icon-alert txt-dark"></i>


    },
    {
        id: 8,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark inverse',
        btnclose: 'default',
        icon: <i className="icon-info-alt"></i>

    }
];

export const IconsWithOutlineData = [
    {
        id: 2,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'secondary outline',
        btnclose: 'default',
        icon: <Heart />
    },
    {
        id: 3,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'success outline',
        btnclose: 'default',
        icon: <ThumbsUp />
    },
    {
        id: 4,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'info outline',
        btnclose: 'default',
        icon: <HelpCircle />
    },
    {
        id: 5,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'warning outline',
        btnclose: 'default',
        icon: <Bell />
    },
    {
        id: 6,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'danger outline',
        btnclose: 'default',
        icon: <ThumbsDown />
    },
    {
        id: 7,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'light outline',
        btnclose: 'default',
        icon: <AlertTriangle />
    },
    {
        id: 8,
        alerttxt: 'You can check in on some of those fields below.',
        alertcolor: 'dark dark',
        btnclose: 'default',
        icon: <AlertCircle />
    }
];

export const TextAsActionsData = [
    {
        id: 2,
        alertcolor: 'secondary  inverse alert-dismissible',
        alerttxt: 'Oh snap! Change a few things up and submit again.',
        btnClose: 'close',
        icon: <i className="icon-heart"></i>,
        divCls: 'bg-secondary',
        btnContent: '<span className="bg-secondary" >dismiss</span>'
    },
    {
        id: 3,
        alertcolor: 'success inverse alert-dismissible',
        alerttxt: 'Your time Over after <b> 5 </b> miniute',
        btnClose: 'close',
        icon: <i className="icon-thumb-up alert-center"></i>,
        divCls: 'bg-success',
        btnContent: '<span className="bg-success">dismiss</span>'
    },
    {
        id: 4,
        alertcolor: 'info inverse alert-dismissible',
        alerttxt: '<b>Welcome!</b> Start your day with some alerts.',
        btnClose: 'close',
        icon: <i className="icon-help-alt"></i>,
        divCls: 'bg-info',
        btnContent: '<span className="bg-primary ">dismiss</span>'
    },
    {
        id: 5,
        alertcolor: 'warning inverse alert-dismissible',
        alerttxt: '<b> Congratulation!</b>Your Product Added in Like List',
        btnClose: 'close',
        icon: <i className="icon-bell"></i>,
        divCls: 'bg-warning',
        btnContent: '<span className="bg-warning ">dismiss</span>'
    },
    {
        id: 6,
        alertcolor: 'danger inverse alert-dismissible',
        alerttxt: 'Your <b> Perfomance </b> is down on this week',
        icon: <i className="icon-timer"></i>,
        divCls: 'bg-danger',
        btnContent: '<span className="bg-danger ">dismiss</span>'
    },
    {
        id: 7,
        alertcolor: 'light inverse alert-dismissible',
        alerttxt: 'You can check in on some of those fields below.',
        icon: <i className="icon-alert txt-dark"></i>,
        divCls: 'bg-light txt-dark',
        btnContent: '<span className="bg-light txt-dark">dismiss</span>'
    },
    {
        id: 8,
        alertcolor: 'dark inverse alert-dismissible',
        alerttxt: 'You can check in on some of those fields below.',
        icon: <i className="icon-info-alt"></i>,
        divCls: 'bg-dark',
        btnContent: '<span className="bg-dark txt-dark ">dismiss</span>'
    }
];

export const RoundedData = [
    {
        id: 1,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Secondary button',
        btncolor: 'secondary',
        btnclass: 'btn-round rounded-pill'
    },
    {
        id: 2,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Success button',
        btncolor: 'success',
        btnclass: 'btn-round rounded-pill'
    },
    {
        id: 3,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Info  button',
        btncolor: 'info',
        btnclass: 'btn-round rounded-pill'
    },
    {
        id: 4,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Warning button',
        btncolor: 'warning',
        btnclass: 'btn-round rounded-pill'
    },
    {
        id: 5,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Danger button',
        btncolor: 'danger',
        btnclass: 'btn-round rounded-pill'
    }
];

export const SplitData = [
    {
        id: 1,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Secondary Button',
        btncolor: 'secondary'
    },
    {
        id: 2,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Success Button',
        btncolor: 'success'
    },
    {
        id: 3,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Info Button',
        btncolor: 'info'
    },
    {
        id: 4,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Warning Button',
        btncolor: 'warning',
    },
    {
        id: 5,
        items: [
            { item: 'Link 1' },
            { item: 'Link 1' },
            { item: 'Link 1' }
        ],
        btnText: 'Danger Button',
        btncolor: 'danger'
    }
];

export const BasicColorData = [
    {
        id:1,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btncolor: 'secondary'
    },
    {
        id:2,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btncolor: 'success'
    },
    {
        id:3,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btncolor: 'info'
    },
    {
        id:4,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btncolor: 'warning',
    },
    {
        id:5,
        items: [
            { item: 'Action' },
            { item: 'Another Action' },
            { item: 'Something Else' },
            { item: 'Separated Link' }
        ],
        btnText: 'Action',
        btncolor: 'danger'
    }
];

export const BasicPopoverData = [
    {
        id: 2,
        Popoverheader: 'Popover title',
        Popoverbody: 'And heres some amazing content. Its very engaging. Right?',
        btncolor: 'primary',
        btntext: 'Dismissible popover'
    },
    {
        id: 3,
        placement: 'bottom',
        Popoverheader: 'Popover title',
        Popoverbody: 'And heres some amazing content. Its very engaging. Right?',
        btncolor: 'primary',
        btntext: 'On Hover Tooltip',
        trigger: 'hover'
    },
];

export const DirectionData = [
    {
        id: 5,
        placement: 'right',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on right'
    },
    {
        id: 6,
        placement: 'bottom',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on bottom'
    },
    {
        id: 7,
        placement: 'left',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: 'Popover on left'
    },
];

export const OffsetPopoverData = [
    {
        id: 13,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '0px',
        offset: [0]
    },
    {
        id: 14,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '20px 20px On Hover',
        offset: [20, 20],
        trigger: 'hover'

    },
    {
        id: 15,
        placement: 'top',
        Popoverheader: 'Popover title',
        Popoverbody: 'Vivamus sagittis lacus vel augue laoreet rutrum faucibus.',
        btncolor: 'primary',
        btntext: '0px On Hover',
        offset: [0],
        trigger: 'hover'

    },
];