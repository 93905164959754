// dashbaord
import Slidersection from "../Components/Application/Homepage/Slidersection";
import SliderList from "../Components/Application/Homepage/SliderList/SliderList";
import Default from "../Components/Dashboard/Default";
import Ecommerce from "../Components/Dashboard/Ecommerce";
import Addlogo from "../Components/Application/BasicSetting/Addlogo";
import AddSocial_Links from "../Components/Application/BasicSetting/AddSocial_Links";
import AddwebsiteTitle from "../Components/Application/BasicSetting/AddwebsiteTitle";
import AddFavicon from "../Components/Application/BasicSetting/AddFavicon";
import AboutUs_page from "../Components/Application/AboutUsPage/AboutUs_page";
import AddCrew from "../Components/Application/AboutUsPage/Add Crew";
import Daoform from "../Components/Application/Dao/Daoform";
import Faq from "../Components/Application/FrequentlyAskedQ/Faq";
import FaqList from "../Components/Application/FrequentlyAskedQ/FaqList";
import AddCrmForm from "../Components/Application/CRM/AddCrmForm";
import CrmList from "../Components/Application/CRM/CrmList";
import MakeGroups from "../Components/Application/CRM/MakeGroup/MakeGroups";
import AssignEmailToGroups from "../Components/Application/CRM/MakeGroup/AssignEmailGroups/AssignEmailToGroups";
import ViewEmails from "../Components/Application/CRM/ViewEmails/ViewEmails";
import RunCompagins from "../Components/Application/CRM/RunCompagins/RunCompagins";
import LogScreen from "../Components/Application/CRM/LogScreen/LogScreen";

//widgets
import Chart from "../Components/Widgets/Chart";
import General from "../Components/Widgets/General";

//page-layout
import FooterLight from "../Components/PageLayout/FooterLight";
import FooterFixed from "../Components/PageLayout/FooterFixed";
import FooterDark from "../Components/PageLayout/FooterDark";
//ui-kits
import Typography from "../Components/UiKits/Typography";
import Avatars from "../Components/UiKits/Avtars";
import HelperClasses from "../Components/UiKits/HelperClass";
import Grid from "../Components/UiKits/Grid";
import TagAndPills from "../Components/UiKits/TagAndPills";
import Progressbars from "../Components/UiKits/Progress";
import Modals from "../Components/UiKits/Modals";
import Alert from "../Components/UiKits/Alert";
import Popover from "../Components/UiKits/Popover";
import Tooltips from "../Components/UiKits/Tooltip";
import Spinners from "../Components/UiKits/Spinners";
import Dropdowns from "../Components/UiKits/Dropdown";
import Accordian from "../Components/UiKits/Accordian";
import TabBootstrap from "../Components/UiKits/Tabs/BootstrapTab";
import TabLine from "../Components/UiKits/Tabs/LineTab";
import Navs from "../Components/UiKits/Navs";
import Shadow from "../Components/UiKits/Shadows";
import List from "../Components/UiKits/Lists";

//Bonus-ui
import Scrollable from "../Components/Bonus-Ui/Scrollable";
import TreeView from "../Components/Bonus-Ui/TreeView";
import BootstrapNotify from "../Components/Bonus-Ui/BootstrapNotify";
import RatingClass from "../Components/Bonus-Ui/Rating";
import Dropzones from "../Components/Bonus-Ui/Dropzones";
import Tours from "../Components/Bonus-Ui/Tour";
import SweetAlerts from "../Components/Bonus-Ui/SweetAlert";
import Carousels from "../Components/Bonus-Ui/Carousel";
import Ribbons from "../Components/Bonus-Ui/Ribbons";
import Paginations from "../Components/Bonus-Ui/Pagination";
import Steps from "../Components/Bonus-Ui/Steps";
import BreadcrumbClass from "../Components/Bonus-Ui/Breadcrumb";
import RangeSlider from "../Components/Bonus-Ui/RangeSlider";
import Imagecropper from "../Components/Bonus-Ui/ImageCropper";
import Sticky from "../Components/Bonus-Ui/Sticky";
import DragAndDrop from "../Components/Bonus-Ui/DragAndDrop";
import UploadImage from "../Components/Bonus-Ui/ImageUpload";
import BasicCards from "../Components/Bonus-Ui/Cards/BasicCards/index";
import CreativeCards from "../Components/Bonus-Ui/Cards/CreativeCards";
import TabCard from "../Components/Bonus-Ui/Cards/TabCard";
import DraggingCards from "../Components/Bonus-Ui/Cards/DragCards/index";
import Timeline1 from "../Components/Bonus-Ui/Timeline/Timeline1";
// Icons
import WeatherIcon from "../Components/Icons/WeatherIcon";
import FeatherIcon from "../Components/Icons/FeatherIcon";
import ThemifyIcon from "../Components/Icons/ThemifyIcon";
import IcoIcon from "../Components/Icons/IcoIcon";
import FontawesomeIcon from "../Components/Icons/FontawesomeIcon";
import FlagIcon from "../Components/Icons/FlagIcon";

// Buttons
import DefaultButton from "../Components/Buttons/Default";
import FlatButton from "../Components/Buttons/FlatBtn/index";
import EdgeButton from "../Components/Buttons/Edge/index";
import RaisedButton from "../Components/Buttons/RaisedBtn/index";
import GroupButton from "../Components/Buttons/GroupBtn/index";

//chart
import ApexCharts from "../Components/Charts/ApexCharts";
// import Chartist from "../Components/Charts/ChartistCharts";
import ChartJs from "../Components/Charts/ChartsJs";
import GoogleCharts from "../Components/Charts/GoogleCharts";
import KnobChart from "../Components/Charts/KnobChart";

//forms
import FormValidation from "../Components/Forms/FormControl/FormValidation";
import BaseInput from "../Components/Forms/FormControl/BaseInput";
import CheckboxandRadio from "../Components/Forms/FormControl/CheckRadio";
import InputGroups from "../Components/Forms/FormControl/InputGroups";
import MegaOption from "../Components/Forms/FormControl/MegaOption";
import Datepicker from "../Components/Forms/FormWidget/DatePicker";
import Timepicker from "../Components/Forms/FormWidget/TimepickerComponent/TimePicker";
import TypeaheadComp from "../Components/Forms/FormWidget/TypeaheadComponent/Typeahead";
import FormDefault from "../Components/Forms/FormLayout/Default";
import FormWizard from "../Components/Forms/FormLayout/FormWizard1/FormWizard1";
import BasicTables from "../Components/Tables/Reactstrap/BasicTable";
import BorderTable from "../Components/Tables/Reactstrap/BorderTable";
import SizingTable from "../Components/Tables/Reactstrap/SizingTable";
import StylingTable from "../Components/Tables/Reactstrap/StylingTable";
import DataTables from "../Components/Tables/DataTable";

//Application
import Newproject from "../Components/Application/Project/Newproject";
import Project from "../Components/Application/Project/Project";
import Chat from "../Components/Application/Chat/ChatApp";
import VideoChat from "../Components/Application/Chat/VideoChat";
import ContactFirebase from "../Components/Application/Contact/ContactFirebase";
import Contact from "../Components/Application/Contact/ContactApp";
import Task from "../Components/Application/Task";
import TodoContain from "../Components/Application/Todo";

//Gallary
import ImageGallery from "../Components/Gallery/ImageGallery";
import MesonryDesc from "../Components/Gallery/MesonryDesc";
import MasonryGallery from "../Components/Gallery/MesonryGallery";
import ImageWithDesc from "../Components/Gallery/ImageWithDesc";
import ImageHover from "../Components/Gallery/ImageHover";

//Blog
import BlogDetail from "../Components/Blog/BlogDetail";
import BlogPost from "../Components/Blog/BlogPost";
import BlogSingle from "../Components/Blog/BlogSingle";

//Pages
import SamplePage from "../Components/Pages/Sample";

//Book mark
import BookmarksContain from "../Components/Application/BookMarks";

// To-do app
import TodoFirebaseContain from "../Components/Application/TodoFirebase";

//User app
import UsersCardssContain from "../Components/Application/Users/UsersCards";
import UsersEditContain from "../Components/Application/Users/UsersEdit";
import UsersProfileContain from "../Components/Application/Users/UsersProfile";

//social app
import SocialAppContain from "../Components/Application/SocialApp";

//calender
import CalenderContain from "../Components/Application/Calender/Index";

//Email
import MailInboxContain from "../Components/Application/Email/MailInbox";
import ComposeContain from "../Components/Application/Email/Compose";
import ReadMailContain from "../Components/Application/Email/ReadMail";

//File Manager
import FileManagerContain from "../Components/Application/FileManager";
import SearchResultContain from "../Components/Application/SearchResult";

//E-commerce
import Products from "../Components/Application/Ecommerce/Products";
import ProductCart from "../Components/Application/Ecommerce/ProductCart";
import WishList from "../Components/Application/Ecommerce/Wishlist";
import CheckOut from "../Components/Application/Ecommerce/CheckOut";
import Invoice from "../Components/Application/Ecommerce/Invoice";
import OrderHistory from "../Components/Application/Ecommerce/OrderHistory";
import ProductPage from "../Components/Application/Ecommerce/ProductPage";
import PricingMemberShip from "../Components/Application/Ecommerce/PricingMemberShip";
import PaymentDetails from "../Components/Application/Ecommerce/PaymentDetails";
import ProductListContain from "../Components/Application/Ecommerce/ProductList";
import KanbanBoardContain from "../Components/Application/KanbanBoard";
import DraggableContain from "../Components/Application/Calender/DraggableCalender";

//Job Search
import JobCards from "../Components/JobSearch/JobCardView";
import ListView from "../Components/JobSearch/ListView";
import JobDetails from "../Components/JobSearch/JobDetail";
import Apply from "../Components/JobSearch/Apply";

//Learning
import LearningList from "../Components/Learning/LearningList";
import DetailCourse from "../Components/Learning/DetailCourse";

//Map
import GoogleMaps from "../Components/Map/GoogleMap";
import LeafletMapContain from "../Components/Map/LeafletMap";

//Editor
import CkEditorContain from "../Components/Editor/CkEditor";
import MdeEDitorContain from "../Components/Editor/MDEEditor/MDEEditor";
import ACEEditorContain from "../Components/Editor/AceCodeEditor/ACECodeEditor";

import KnowledgeBaseContain from "../Components/Knowledgebase/KnowledgeBase";
import KnowledgeCategoryContain from "../Components/Knowledgebase/KnowledgeCategory";
import KnowledgeDetailContain from "../Components/Knowledgebase/KnowledgeDetails";
import SupportTickitContain from "../Components/SupportTicket";
import DateTimeContain from "../Components/Forms/FormWidget/DateandTime";
import FormDateRangepickerContain from "../Components/Forms/FormWidget/FormDateRangePicker";
import FormSelectContain from "../Components/Forms/FormWidget/FormSelect2";
import FormSwitchContain from "../Components/Forms/FormWidget/FormSwitch";
import FormTouchspinContain from "../Components/Forms/FormWidget/FormTouchspin";
import FormClipboardContain from "../Components/Forms/FormWidget/FormClipboard";
import FAQContent from "../Components/FAQ";
import CrewList from "../Components/Application/AboutUsPage/CrewList/CrewList";

/****** CV Template *****/
import CvHero from "../Components/CVTemplate/CvHero/CvHero";
import CvHeroList from "../Components/CVTemplate/CvHero/CvHeroList";
import CvCategories from "../Components/CVTemplate/CvCategories/CvCategories";
import CvCategoryList from "../Components/CVTemplate/CvCategories/CvCategoryList";
import CvHomeSeo from "../Components/CVTemplate/CvSeo/CvHomeSeo";
import CvAboutSeo from "../Components/CVTemplate/CvSeo/CvAboutSeo";
import CvSkillsSeo from "../Components/CVTemplate/CvSeo/CvSkillsSeo";
import CvProductSeo from "../Components/CVTemplate/CvSeo/CvProductSeo";
import CvProjectsSeo from "../Components/CVTemplate/CvSeo/CvProjectsSeo";
import CvContactSeo from "../Components/CVTemplate/CvSeo/CvContactSeo";
import CvSkills from "../Components/CVTemplate/CvSkills/CvSkills";
import CvSkillsList from "../Components/CVTemplate/CvSkills/CvSkillsList";
import CvSkillsPageSection from "../Components/CVTemplate/CvPageSections/CvSkillsPageSection";
import CvProjectPageSection from "../Components/CVTemplate/CvPageSections/CvProjectPageSection";
import CvProductsPageSection from "../Components/CVTemplate/CvPageSections/CvProductsPageSection";
import CvProjects from "../Components/CVTemplate/CvProjects/CvProjects";
import CvProjectsList from "../Components/CVTemplate/CvProjects/CvProjectsList";
import CvProduct from "../Components/CVTemplate/CvProducts/CvProduct";
import CvProductList from "../Components/CVTemplate/CvProducts/CvProductList";
import CvFooter from "../Components/CVTemplate/CvFooter/CvFooter";
import CvFooterLinks from "../Components/CVTemplate/CvFooter/CvFooterLinks";
import CvFooterLinksList from "../Components/CVTemplate/CvFooter/CvFooterLinksList";
import CvFavicon from "../Components/CVTemplate/CvSeo/CvFavicon";

//Book mark

export const routes = [
  
  {
    path: `${process.env.PUBLIC_URL}/hero/add_hero`,
    Component: <CvHero />,
  },
  {
    path: `${process.env.PUBLIC_URL}/hero/hero_list`,
    Component: <CvHeroList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/category/add_category`,
    Component: <CvCategories />,
  },
  {
    path: `${process.env.PUBLIC_URL}/category/category_list`,
    Component: <CvCategoryList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/skills/add_skills`,
    Component: <CvSkills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/skills/skills_list`,
    Component: <CvSkillsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/add_project`,
    Component: <CvProjects />,
  },
  {
    path: `${process.env.PUBLIC_URL}/project/project_list`,
    Component: <CvProjectsList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/add_product`,
    Component: <CvProduct />,
  },
  {
    path: `${process.env.PUBLIC_URL}/product/product_list`,
    Component: <CvProductList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/homepage`,
    Component: <CvHomeSeo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/aboutpage`,
    Component: <CvAboutSeo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/skillspage`,
    Component: <CvSkillsSeo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/productspage`,
    Component: <CvProductSeo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/projectspage`,
    Component: <CvProjectsSeo />,
  },
  {
    path: `${process.env.PUBLIC_URL}/seo/contactpage`,
    Component: <CvContactSeo />,
  },
  // Page Sections
  {
    path: `${process.env.PUBLIC_URL}/sections/skills_page`,
    Component: <CvSkillsPageSection />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sections/projects_page`,
    Component: <CvProjectPageSection />,
  },
  {
    path: `${process.env.PUBLIC_URL}/sections/products_page`,
    Component: <CvProductsPageSection />,
  },
  {
    path: `${process.env.PUBLIC_URL}/footer/footer_section`,
    Component: <CvFooter />,
  },
  {
    path: `${process.env.PUBLIC_URL}/footer/footer_social_links`,
    Component: <CvFooterLinks />,
  },
  {
    path: `${process.env.PUBLIC_URL}/footer/footer_social_links_list`,
    Component: <CvFooterLinksList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/favicon`,
    Component: <CvFavicon />,
  },
  /******** CV Template ********/ 
  //!Settings End
  //dashboard
  {
    path: `${process.env.PUBLIC_URL}/dashboard/default`,
    Component: <Default />,
  },
  {
    path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`,
    Component: <Ecommerce />,
  },

  {
    path: `/dashboard/slider`,
    Component: <Slidersection />,
  },
  {
    path: `/dashboard/sliderlist`,
    Component: <SliderList />,
  },
  {
    path: `/dashboard/add-logo`,
    Component: <Addlogo />,
  },
  { path: `/dashboard/add-social-links`, Component: <AddSocial_Links /> },
  { path: `/dashboard/add-web-title`, Component: <AddwebsiteTitle /> },
  { path: `/dashboard/add-fav-icon`, Component: <AddFavicon /> },
  { path: `/dashboard/aboutus-data`, Component: <AboutUs_page /> },
  { path: `/dashboard/addcrew`, Component: <AddCrew /> },
  { path: `/dashboard/crewlist`, Component: <CrewList /> },
  { path: `/dashboard/deoform`, Component: <Daoform /> },
  { path: `/dashboard/faq`, Component: <Faq /> },
  { path: `/dashboard/faqlist`, Component: <FaqList /> },
  { path: `/dashboard/crm-form`, Component: <AddCrmForm /> },
  { path: `/dashboard/crm-list`, Component: <CrmList /> },
  { path: `/dashboard/make-group`, Component: <MakeGroups /> },
  { path: `/dashboard/assign-email`, Component: <AssignEmailToGroups /> },
  { path: `/dashboard/view-email`, Component: <ViewEmails /> },
  { path: `/dashboard/run-compagin`, Component: <RunCompagins /> },
  { path: `/dashboard/log-screen`, Component: <LogScreen /> },

  // //widgets
  { path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General /> },
  { path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Chart /> },

  // //page-layout
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-light`,
    Component: <FooterLight />,
  },
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-fixed`,
    Component: <FooterFixed />,
  },
  {
    path: `${process.env.PUBLIC_URL}/page-layout/footer-dark`,
    Component: <FooterDark />,
  },

  //ui-kits
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/typography`,
    Component: <Typography />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/avatars`, Component: <Avatars /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/helper-classes`,
    Component: <HelperClasses />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/grid`, Component: <Grid /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tag-pills`,
    Component: <TagAndPills />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/progress`,
    Component: <Progressbars />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/modal`, Component: <Modals /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/alert`, Component: <Alert /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/popover`, Component: <Popover /> },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tooltip`,
    Component: <Tooltips />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/spinners`,
    Component: <Spinners />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/dropdown`,
    Component: <Dropdowns />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/accordion`,
    Component: <Accordian />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/tab-bootstrap`,
    Component: <TabBootstrap />,
  },
  {
    path: `${process.env.PUBLIC_URL}/ui-kits/line-tabs`,
    Component: <TabLine />,
  },
  { path: `${process.env.PUBLIC_URL}/ui-kits/navs`, Component: <Navs /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/shadow`, Component: <Shadow /> },
  { path: `${process.env.PUBLIC_URL}/ui-kits/lists`, Component: <List /> },

  // //Bonus Ui
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/scrollable`,
    Component: <Scrollable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/tree-view`,
    Component: <TreeView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/bootstrap-notify`,
    Component: <BootstrapNotify />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rating`,
    Component: <RatingClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dropzone`,
    Component: <Dropzones />,
  },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/tour`, Component: <Tours /> },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/sweet-alert`,
    Component: <SweetAlerts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/carousel`,
    Component: <Carousels />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/ribbons`,
    Component: <Ribbons />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/pagination`,
    Component: <Paginations />,
  },
  { path: `${process.env.PUBLIC_URL}/bonus-ui/steps`, Component: <Steps /> },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/breadcrumb`,
    Component: <BreadcrumbClass />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/rangeSlider`,
    Component: <RangeSlider />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/imageCropper`,
    Component: <Imagecropper />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/stickyNotes`,
    Component: <Sticky />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/dragNDropComp`,
    Component: <DragAndDrop />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/image-upload`,
    Component: <UploadImage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/basicCards`,
    Component: <BasicCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/creativeCards`,
    Component: <CreativeCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/tabCard`,
    Component: <TabCard />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/card/draggingCards`,
    Component: <DraggingCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/bonus-ui/timelines/timeline1`,
    Component: <Timeline1 />,
  },

  // //Icons
  {
    path: `${process.env.PUBLIC_URL}/icons/flagIcons`,
    Component: <FlagIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`,
    Component: <FontawesomeIcon />,
  },
  { path: `${process.env.PUBLIC_URL}/icons/icoIcons`, Component: <IcoIcon /> },
  {
    path: `${process.env.PUBLIC_URL}/icons/themifyIcons`,
    Component: <ThemifyIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/featherIcons`,
    Component: <FeatherIcon />,
  },
  {
    path: `${process.env.PUBLIC_URL}/icons/weatherIcons`,
    Component: <WeatherIcon />,
  },

  // //Buttons
  {
    path: `${process.env.PUBLIC_URL}/buttons/default-btn`,
    Component: <DefaultButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/flatBtn`,
    Component: <FlatButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/edgeBtn`,
    Component: <EdgeButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/raisedBtn`,
    Component: <RaisedButton />,
  },
  {
    path: `${process.env.PUBLIC_URL}/buttons/groupBtn`,
    Component: <GroupButton />,
  },

  // //Charts
  {
    path: `${process.env.PUBLIC_URL}/charts/apexCharts`,
    Component: <ApexCharts />,
  },
  // {
  //   path: `${process.env.PUBLIC_URL}/charts/chartist`,
  //   Component: <Chartist />,
  // },
  { path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs /> },
  {
    path: `${process.env.PUBLIC_URL}/charts/googleChart`,
    Component: <GoogleCharts />,
  },
  {
    path: `${process.env.PUBLIC_URL}/charts/knobChart`,
    Component: <KnobChart />,
  },

  // // Forms
  {
    path: `${process.env.PUBLIC_URL}/forms/formValidation`,
    Component: <FormValidation />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/baseInput`,
    Component: <BaseInput />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/radio-checkbox`,
    Component: <CheckboxandRadio />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/inputGroup`,
    Component: <InputGroups />,
  },
  {
    path: `${process.env.PUBLIC_URL}/forms/megaOptions`,
    Component: <MegaOption />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datepicker`,
    Component: <Datepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/timepicker`,
    Component: <Timepicker />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/datetimepicker`,
    Component: <DateTimeContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/typeahead`,
    Component: <TypeaheadComp />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/rangepicker`,
    Component: <FormDateRangepickerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/select2`,
    Component: <FormSelectContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/switch`,
    Component: <FormSwitchContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/touchspin`,
    Component: <FormTouchspinContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-widget/clipboard`,
    Component: <FormClipboardContain />,
  },

  {
    path: `${process.env.PUBLIC_URL}/form-layout/formDefault`,
    Component: <FormDefault />,
  },
  {
    path: `${process.env.PUBLIC_URL}/form-layout/formWizard`,
    Component: <FormWizard />,
  },

  //Tables
  { path: `${process.env.PUBLIC_URL}/table/basic`, Component: <BasicTables /> },
  {
    path: `${process.env.PUBLIC_URL}/table/sizing`,
    Component: <SizingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/border`,
    Component: <BorderTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/styling`,
    Component: <StylingTable />,
  },
  {
    path: `${process.env.PUBLIC_URL}/table/data-table`,
    Component: <DataTables />,
  },

  //Applicatiion
  {
    path: `${process.env.PUBLIC_URL}/app/project/project-list`,
    Component: <Project />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/project/new-project`,
    Component: <Newproject />,
  },
  { path: `${process.env.PUBLIC_URL}/app/chat-app`, Component: <Chat /> },
  {
    path: `${process.env.PUBLIC_URL}/app/chat-video-app`,
    Component: <VideoChat />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/contact-firebase`,
    Component: <ContactFirebase />,
  },
  { path: `${process.env.PUBLIC_URL}/app/contact`, Component: <Contact /> },
  { path: `${process.env.PUBLIC_URL}/app/task`, Component: <Task /> },
  {
    path: `${process.env.PUBLIC_URL}/app/bookmark`,
    Component: <BookmarksContain />,
  },
  { path: `${process.env.PUBLIC_URL}/app/todo`, Component: <TodoContain /> },
  {
    path: `${process.env.PUBLIC_URL}/app/todo-firebase`,
    Component: <TodoFirebaseContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userProfile`,
    Component: <UsersProfileContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userEdit`,
    Component: <UsersEditContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/users/userCards`,
    Component: <UsersCardssContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/social-app`,
    Component: <SocialAppContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/calendar/basic-calendar`,
    Component: <CalenderContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/calendar/draggable-calendar`,
    Component: <DraggableContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/mailbox`,
    Component: <MailInboxContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/readmail`,
    Component: <ReadMailContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/email/compose`,
    Component: <ComposeContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/file-manager`,
    Component: <FileManagerContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/search-result`,
    Component: <SearchResultContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product`,
    Component: <Products />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-page/:id`,
    Component: <ProductPage />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/payment-details`,
    Component: <PaymentDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/orderhistory`,
    Component: <OrderHistory />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/invoice`,
    Component: <Invoice />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/cart`,
    Component: <ProductCart />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/wishlist`,
    Component: <WishList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/checkout`,
    Component: <CheckOut />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/pricing`,
    Component: <PricingMemberShip />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/ecommerce/product-list`,
    Component: <ProductListContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/kanban-board`,
    Component: <KanbanBoardContain />,
  },

  //Gallery
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageGallery`,
    Component: <ImageGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageWithDesc`,
    Component: <ImageWithDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/mesonryGallery`,
    Component: <MasonryGallery />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/mesonryDesc`,
    Component: <MesonryDesc />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/gallery/imageHover`,
    Component: <ImageHover />,
  },

  //page
  {
    path: `${process.env.PUBLIC_URL}/pages/sample-page`,
    Component: <SamplePage />,
  },

  //Blog
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogDetail`,
    Component: <BlogDetail />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogSingle`,
    Component: <BlogSingle />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/blog/blogPost`,
    Component: <BlogPost />,
  },

  //Job Search
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/cardView`,
    Component: <JobCards />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-list`,
    Component: <ListView />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-detail`,
    Component: <JobDetails />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/jobSearch/job-apply`,
    Component: <Apply />,
  },

  //Learning
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learning-list`,
    Component: <LearningList />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/learning/learning-detail`,
    Component: <DetailCourse />,
  },

  //Map
  {
    path: `${process.env.PUBLIC_URL}/app/map/googleMap`,
    Component: <GoogleMaps />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/map/leaflet-map`,
    Component: <LeafletMapContain />,
  },

  //Editor
  {
    path: `${process.env.PUBLIC_URL}/editor/ckEditor`,
    Component: <CkEditorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/mdeEditor`,
    Component: <MdeEDitorContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/editor/ace-code-editor`,
    Component: <ACEEditorContain />,
  },

  //Knowledge
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase`,
    Component: <KnowledgeBaseContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-category`,
    Component: <KnowledgeCategoryContain />,
  },
  {
    path: `${process.env.PUBLIC_URL}/app/knowledgebase-detail`,
    Component: <KnowledgeDetailContain />,
  },

  //Support Ticket
  {
    path: `${process.env.PUBLIC_URL}/app/support-ticket`,
    Component: <SupportTickitContain />,
  },

  //Faq
  { path: `${process.env.PUBLIC_URL}/app/faq`, Component: <FAQContent /> },
];
