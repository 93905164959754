import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { Breadcrumbs } from "../../../AbstractElements";
import { useNavigate, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import { insertHeroSection, updateHeroSection } from "../../../api/api";
import { toast } from "react-toastify";

function CvHero() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);

  const [title, setTitle] = useState("");
  const [texts, setTexts] = useState("");
  const [btnOne, setBtnOne] = useState("");
  const [btnOneUrl, setBtnOneUrl] = useState("");
  const [btnTwo, setBtnTwo] = useState("");
  const [btnTwoUrl, setBtnTwoUrl] = useState("");
  const [imgFile, setImgFile] = useState("");

  const handleAddBlogs = async () => {
    if (!title) {
      toast.error("Please fill all the required fields.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("hero_title", title);
    formData.append("hero_text", texts);
    formData.append("hero_btnone", btnOne);
    formData.append("hero_btntwo", btnTwo);
    formData.append("hero_btnone_url", btnOneUrl);
    formData.append("hero_btntwo_url", btnTwoUrl);
    formData.append("hero_image", imgFile);

    const res = await insertHeroSection(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/hero/hero_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const updateAddBlogs = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("hero_title", title);
    formData.append("hero_text", texts);
    formData.append("hero_btnone", btnOne);
    formData.append("hero_btntwo", btnTwo);
    formData.append("hero_btnone_url", btnOneUrl);
    formData.append("hero_btntwo_url", btnTwoUrl);
    formData.append("hero_image", imgFile);

    const res = await updateHeroSection(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/hero/hero_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const populateData = () => {
    if (location.state) {
      setTitle(location.state.dataObj.hero_title);
      setTexts(location.state.dataObj.hero_text);
      setBtnOne(location.state.dataObj.hero_btnone);
      setBtnOneUrl(location.state.dataObj.hero_btnone_url);
      setBtnTwo(location.state.dataObj.hero_btntwo);
      setBtnTwoUrl(location.state.dataObj.hero_btntwo_url);
      setImgFile(location.state.dataObj.hero_image);
    }
  };

  useEffect(() => {
    populateData();
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    setImgFile(file);
  };

  return (
    <Fragment>
      <Breadcrumbs
        parent="Hero Section"
        title="Add Hero"
        mainTitle="Add Hero"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col md={"6"}>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          value={title}
                          placeholder="Enter Title"
                          type="text"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col md={"6"}>
                      <FormGroup>
                        <Label>{"Text"}</Label>
                        <Input
                          onChange={(e) => setTexts(e.target.value)}
                          value={texts}
                          type="text"
                          placeholder="Enter Text"
                          className="form-control"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Button Text <sup>*</sup>
                        </Label>
                        <input
                          onChange={(e) => setBtnOne(e.target.value)}
                          value={btnOne}
                          type="text"
                          className="form-control"
                          placeholder="Enter Button Text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={"6"}>
                      <FormGroup>
                        <Label>
                          Button Url <sup>*</sup>
                        </Label>
                        <input
                          onChange={(e) => setBtnOneUrl(e.target.value)}
                          value={btnOneUrl}
                          type="text"
                          className="form-control"
                          placeholder="Enter Button Url"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <Label>
                          Button Text <sup>Copy Email</sup>
                        </Label>
                        <input
                          onChange={(e) => setBtnTwo(e.target.value)}
                          value={btnTwo}
                          type="text"
                          className="form-control"
                          placeholder="Enter Button Text"
                        />
                      </FormGroup>
                    </Col>
                    <Col md={"6"}>
                      <FormGroup>
                        <Label>
                          Button Text <sup>Copy Email</sup>
                        </Label>
                        <input
                          onChange={(e) => setBtnTwoUrl(e.target.value)}
                          value={btnTwoUrl}
                          type="text"
                          className="form-control"
                          placeholder="Enter Button Url"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Image"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location.state ? (
                          <Button
                            onClick={() =>
                              updateAddBlogs(location.state.dataObj.hero_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {loading ? <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                              <span>Updating..</span>{" "}
                              <ThreeDots width={19} height={19} color="#fff" />
                            </div> : "Update"}
                          </Button>
                        ) : (
                          <Button
                            onClick={handleAddBlogs}
                            className="me-3"
                            color="success"
                          >
                            {loading ? <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
                              <span>Submiting..</span>{" "}
                              <ThreeDots width={19} height={19} color="#fff" />
                            </div> : "Submit"}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CvHero;
