import React, { Fragment, useEffect, useState } from "react";
import { Breadcrumbs } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import { getCvFavicon, updateCvFavicon } from "../../../api/api";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'

const CvFavicon = () => {
  const [imgFile, setImgFile] = useState("");
  const [idState, setIdState] = useState([]);

  useEffect(() => {
    getCvFavicon()
      .then((res) => {
        setImgFile(res?.data[0]?.favicon_img);
        setIdState(res?.data[0]?.favicon_id);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const onUpdatePage = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append("favicon_img", imgFile);

    await updateCvFavicon(idState, formData);
    alert(`Updated!`);
  };

  const handleChangeStatus = ({ meta, file }, status) => {
    setImgFile(file);
  };

  return (
    <>
      <Fragment>
        <Breadcrumbs
          parent="Home Page"
          title="Home Page"
          mainTitle="Home Page"
        />
        <Container fluid={true}>
          <Row>
            <Col sm="12">
              <Card>
                <CardBody>
                  <Form>
                    <Row>
                      <Col>
                        <FormGroup>
                          <Label>{"Favicon Image"}</Label>
                          <Dropzone
                            onChangeStatus={handleChangeStatus}
                            maxFiles={1}
                            multiple={false}
                            canCancel={false}
                            inputContent="Drop A File"
                            styles={{
                              dropzone: { width: "100%", minHeight: 50 },
                              dropzoneActive: { borderColor: "green" },
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <FormGroup className="mb-0">
                          <Button
                            type="submit"
                            onClick={onUpdatePage}
                            color="success"
                          >
                            Update
                          </Button>
                        </FormGroup>
                      </Col>
                    </Row>
                  </Form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </Fragment>
    </>
  );
};

export default CvFavicon;
