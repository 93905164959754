import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { Breadcrumbs } from "../../../AbstractElements";
import { useNavigate, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import {
  getCvCategories,
  insertCvSkills,
  updateCvSkills,
} from "../../../api/api";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

function CvSkills() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [texts, setTexts] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnTextUrl, setBtnTextUrl] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [description, setDescription] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [categories, setCategories] = useState("");
  const [getCategories, setGetCategories] = useState([]);

  const onHandleAdd = async () => {
    if (!title) {
      toast.error("Please fill all the required fields.");
      return;
    }
    if (!imgFile) {
      toast.error("Please fill all the required fields.");
      return;
    }
    setLoading(true);
    const formData = new FormData();
    formData.append("skills_title", title);
    formData.append("skills_texts", texts);
    formData.append("skills_btn", btnText);
    formData.append("skills_btnurl", btnTextUrl);
    formData.append("skills_metakeywords", JSON.stringify(metaKeywords));
    formData.append("skills_metadescription", metaDescription);
    formData.append("skills_description", description);
    formData.append("skills_category_id", categories);
    formData.append("skills_image", imgFile);

    const res = await insertCvSkills(formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/skills/skills_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const onHandleUpdate = async (id) => {
    setLoading(true);
    const formData = new FormData();
    formData.append("skills_title", title);
    formData.append("skills_texts", texts);
    formData.append("skills_btn", btnText);
    formData.append("skills_btnurl", btnTextUrl);
    formData.append("skills_metakeywords", JSON.stringify(metaKeywords));
    formData.append("skills_metadescription", metaDescription);
    formData.append("skills_description", description);
    formData.append("skills_category_id", categories);
    formData.append("skills_image", imgFile);

    const res = await updateCvSkills(id, formData);
    if (res.status === 200) {
      navigate(`${process.env.PUBLIC_URL}/skills/skills_list`);
      setLoading(false);
    } else {
      alert("Error");
      setLoading(false);
    }
  };

  const populateData = () => {
    if (location.state) {
      setTitle(location.state.dataObj.skills_title);
      setTexts(location.state.dataObj.skills_texts);
      setBtnText(location.state.dataObj.skills_btn);
      setBtnTextUrl(location.state.dataObj.skills_btnurl);
      setMetaKeywords(JSON.parse(location.state.dataObj.skills_metakeywords));
      setMetaDescription(location.state.dataObj.skills_metadescription);
      setDescription(location.state.dataObj.skills_description);
      setCategories(location.state.dataObj.skills_category_id);
      setImgFile(location.state.dataObj.skills_image);
    }
  };

  const fetchGetCategories = async () => {
    const res = await getCvCategories(); // API Request
    setGetCategories(res?.data);
  };

  useEffect(() => {
    fetchGetCategories();
    populateData();
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    setImgFile(file);
  };
  function handleChangeDescription(value) {
    setDescription(value);
  }

  return (
    <Fragment>
      <Breadcrumbs parent="Skills" title="Add Skills" mainTitle="Add Skills" />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter Title"
                          className="form-control"
                          value={title}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Text"}</Label>
                        <Input
                          onChange={(e) => setTexts(e.target.value)}
                          placeholder="Enter Text"
                          className="form-control"
                          value={texts}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Button Text"}</Label>
                        <Input
                          onChange={(e) => setBtnText(e.target.value)}
                          placeholder="Enter Button Text"
                          className="form-control"
                          value={btnText}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Button Text Url"}</Label>
                        <Input
                          onChange={(e) => setBtnTextUrl(e.target.value)}
                          placeholder="Enter Url"
                          className="form-control"
                          value={btnTextUrl}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Select Category"}</Label>
                        <Input
                          type="select"
                          value={categories}
                          onChange={(e) => setCategories(e.target.value)}
                          placeholder="Select a Category "
                          className="form-control"
                          required
                        >
                          <option value={""}>Select Category</option>
                          {getCategories?.map((val, i) => (
                            <option value={val.category_id} key={i}>
                              {val.category_name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Meta Keywords"}</Label>
                        <TagsInput
                          value={metaKeywords}
                          onChange={setMetaKeywords}
                          placeHolder="Enter Keywords"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Meta Description"}</Label>
                        <Input
                          onChange={(e) => setMetaDescription(e.target.value)}
                          value={metaDescription}
                          placeholder="Enter Meta Description"
                          className="form-control"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Image"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col>
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <ReactQuill
                          value={description}
                          onChange={handleChangeDescription}
                          placeholder="Type here..."
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "color",
                            "background",
                            "align",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                            "script",
                            "indent",
                            "direction",
                            "font",
                            "size",
                            "blockquote",
                            "code-block",
                          ]}
                          style={{ height: "35vh" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location.state ? (
                          <Button
                            onClick={() =>
                              onHandleUpdate(location.state.dataObj.skills_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Updating...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        ) : (
                          <Button
                            onClick={onHandleAdd}
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Submiting...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CvSkills;
