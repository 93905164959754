import React, { Fragment, useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";
import { ThreeDots } from "react-loader-spinner";
import { Breadcrumbs } from "../../../AbstractElements";
import { useNavigate, useLocation } from "react-router-dom";
import Dropzone from "react-dropzone-uploader";
import 'react-dropzone-uploader/dist/styles.css'
import { toast } from "react-toastify";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import {
  getCvCategories,
  getCvSkillsId,
  insertCvProjects,
  updateCvProjects,
} from "../../../api/api";

function CvProjects() {
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false);
  const [title, setTitle] = useState("");
  const [texts, setTexts] = useState("");
  const [btnText, setBtnText] = useState("");
  const [btnTextUrl, setBtnTextUrl] = useState("");
  const [metaKeywords, setMetaKeywords] = useState("");
  const [metaDescription, setMetaDescription] = useState("");
  const [description, setDescription] = useState("");
  const [imgFile, setImgFile] = useState("");
  const [categories, setCategories] = useState("");
  const [onSkills, setOnSkills] = useState("");
  const [getCategories, setGetCategories] = useState([]);
  const [getSkills, setGetSkills] = useState([]);
  const [multipleImgs, setMultipleImgs] = useState([]);
  const [youtubeLink, setYouTubeLink] = useState("");

  const onHandleAdd = async () => {
    onSkills.forEach(async (elem) => {
      if (!title) {
        toast.error("Please fill all the required fields.");
        return;
      }
      if (!imgFile) {
        toast.error("Please fill all the required fields.");
        return;
      }
      if (!multipleImgs) {
        toast.error("All fields are required");
        return;
      }
      setLoading(true);
      const formData = new FormData();
      formData.append("projects_title", title);
      formData.append("projects_texts", texts);
      formData.append("projects_btn", btnText);
      formData.append("projects_btnurl", btnTextUrl);
      formData.append("projects_metakeywords", JSON.stringify(metaKeywords));
      formData.append("projects_metadescription", metaDescription);
      formData.append("projects_description", description);
      formData.append("projects_category_id", categories);
      formData.append("projects_skill_id", elem.value);
      formData.append("multiple_images", imgFile);
      multipleImgs.forEach((element) => {
        formData.append("multiple_images", element);
      });
      formData.append("project_videourl", youtubeLink);

      const res = await insertCvProjects(formData);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/project/project_list`);
        setLoading(false);
      } else {
        alert("Error");
        setLoading(false);
      }
    });
  };

  const onHandleUpdate = async (id) => {
    if (!title) {
      toast.error("All fields are required");
      return;
    }
    if (imgFile) {
      if (multipleImgs.length == 0) {
        toast.error("Please Select The Mutiple Images");
        return;
      }
    }
    if (multipleImgs.length !== 0) {
      if (!imgFile) {
        toast.error("Please Select The Featured Image");
        return;
      }
    }
    onSkills.forEach(async (elem) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("projects_title", title);
      formData.append("projects_texts", texts);
      formData.append("projects_btn", btnText);
      formData.append("projects_btnurl", btnTextUrl);
      formData.append("projects_metakeywords", JSON.stringify(metaKeywords));
      formData.append("projects_metadescription", metaDescription);
      formData.append("projects_description", description);
      formData.append("projects_category_id", categories);
      formData.append("projects_skill_id", elem.value);
      formData.append("multiple_images", imgFile);
      multipleImgs.forEach((element) => {
        formData.append("multiple_images", element);
      });
      formData.append("project_videourl", youtubeLink);

      const res = await updateCvProjects(id, formData);
      if (res.status === 200) {
        navigate(`${process.env.PUBLIC_URL}/project/project_list`);
        setLoading(false);
      } else {
        alert("Error");
        setLoading(false);
      }
    });
  };

  const populateData = () => {
    if (location.state) {
      setTitle(location.state.dataObj.projects_title);
      setTexts(location.state.dataObj.projects_texts);
      setBtnText(location.state.dataObj.projects_btn);
      setBtnTextUrl(location.state.dataObj.projects_btnurl);
      setMetaKeywords(JSON.parse(location.state.dataObj.projects_metakeywords));
      setMetaDescription(location.state.dataObj.projects_metadescription);
      setDescription(location.state.dataObj.projects_description);
      setCategories(location.state.dataObj.projects_category_id);
      setOnSkills(location.state.dataObj.projects_skill_id);
      setYouTubeLink(location.state.dataObj.project_videourl);
    }
  };

  const handleChangeMultipleImages = (fileObject, status) => {
    const { file } = fileObject;
    const fileExists = multipleImgs.some(
      (existingFile) => existingFile.name === file.name
    );

    if (!fileExists) {
      setMultipleImgs((prevMultipleImgs) => [...prevMultipleImgs, file]);
    }
  };

  const fetchSkills = async (id) => {
    getCvSkillsId(id)
      .then((res) => {
        // console.log(res.data, 'data')
        const mutilOptions = res.data.map((val) => {
          const itemReturn = {
            label: val.skills_title,
            value: val.skills_id,
          };
          return itemReturn;
        });
        setGetSkills(mutilOptions);
        if (res?.data?.length > 0) {
          setOnSkills(res?.data[0]?.skills_id);
        }
      })
      .catch((error) => console.log(error));
  };

  const fetchCategories = async () => {
    const res = await getCvCategories();
    setGetCategories(res.data);

    if (res?.data?.length > 0) {
      fetchSkills(res?.data[0]?.category_id);
      setCategories(res?.data[0]?.category_id);
    }
  };

  useEffect(() => {
    fetchCategories();
    populateData();
  }, []);

  const handleChangeStatus = ({ meta, file }, status) => {
    setImgFile(file);
  };
  function handleChangeDescription(value) {
    setDescription(value);
  }

  return (
    <Fragment>
      <Breadcrumbs
        parent="Projects"
        title="Add Projects"
        mainTitle="Add Projects"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardBody>
                <Form>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Title"}</Label>
                        <Input
                          onChange={(e) => setTitle(e.target.value)}
                          placeholder="Enter Title"
                          className="form-control"
                          value={title}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Text"}</Label>
                        <Input
                          onChange={(e) => setTexts(e.target.value)}
                          placeholder="Enter Text"
                          className="form-control"
                          value={texts}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Button Text"}</Label>
                        <Input
                          onChange={(e) => setBtnText(e.target.value)}
                          placeholder="Enter Button Text"
                          className="form-control"
                          value={btnText}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Button Text Url"}</Label>
                        <Input
                          onChange={(e) => setBtnTextUrl(e.target.value)}
                          placeholder="Enter Url"
                          className="form-control"
                          value={btnTextUrl}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Select Category"}</Label>
                        <Input
                          type="select"
                          value={categories}
                          onChange={(e) => {
                            fetchSkills(e.target.value);
                            setCategories(e.target.value);
                          }}
                          placeholder="Select a Category "
                          className="form-control"
                          required
                        >
                          <option value={""}>Select Category</option>
                          {getCategories?.map((val, i) => (
                            <option value={val.category_id} key={i}>
                              {val.category_name}
                            </option>
                          ))}
                        </Input>
                      </FormGroup>
                    </Col>
                    <Col lg="6">
                      <FormGroup>
                        <Label>Select Skills</Label>
                        <Select
                          onChange={(e) => {
                            setOnSkills(e);
                            // console.log(e);
                          }}
                          options={getSkills}
                          className="js-example-basic-single col-sm-12"
                          isMulti
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Meta Keywords"}</Label>
                        <TagsInput
                          value={metaKeywords}
                          onChange={setMetaKeywords}
                          placeHolder="Enter Keywords"
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={6}>
                      <FormGroup>
                        <Label>{"Meta Description"}</Label>
                        <Input
                          onChange={(e) => setMetaDescription(e.target.value)}
                          value={metaDescription}
                          placeholder="Enter Meta Description"
                          className="form-control"
                          type="text"
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Video Url"}</Label>
                        <Input
                          onChange={(e) => setYouTubeLink(e.target.value)}
                          placeholder="Enter Url"
                          className="form-control"
                          value={youtubeLink}
                          type="text"
                          required
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Featured Image"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeStatus}
                          maxFiles={1}
                          multiple={false}
                          canCancel={false}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup>
                        <Label>{"Slider Images"}</Label>
                        <Dropzone
                          onChangeStatus={handleChangeMultipleImages}
                          multiple={true}
                          canCancel={true}
                          inputContent="Drop A File"
                          styles={{
                            dropzone: { width: "100%", minHeight: 50 },
                            dropzoneActive: { borderColor: "green" },
                          }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row className="mb-5">
                    <Col>
                      <FormGroup>
                        <Label>{"Description"}</Label>
                        <ReactQuill
                          value={description}
                          onChange={handleChangeDescription}
                          placeholder="Type here..."
                          modules={{
                            toolbar: [
                              [{ header: [1, 2, 3, 4, 5, 6, false] }],
                              ["bold", "italic", "underline", "strike"],
                              [{ color: [] }, { background: [] }],
                              [{ align: [] }],
                              [{ list: "ordered" }, { list: "bullet" }],
                              ["link", "image", "video"],
                              ["clean"],
                            ],
                          }}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "color",
                            "background",
                            "align",
                            "list",
                            "bullet",
                            "link",
                            "image",
                            "video",
                            "script",
                            "indent",
                            "direction",
                            "font",
                            "size",
                            "blockquote",
                            "code-block",
                          ]}
                          style={{ height: "35vh" }}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className="mb-0">
                        {location.state ? (
                          <Button
                            onClick={() =>
                              onHandleUpdate(location.state.dataObj.projects_id)
                            }
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Updating...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Update"
                            )}
                          </Button>
                        ) : (
                          <Button
                            onClick={onHandleAdd}
                            className="me-3"
                            color="success"
                          >
                            {loading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  gap: "1rem",
                                }}
                              >
                                <span>Submiting...</span>{" "}
                                <ThreeDots
                                  width={19}
                                  height={19}
                                  color="#fff"
                                />
                              </div>
                            ) : (
                              "Submit"
                            )}
                          </Button>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CvProjects;
