import axios from "axios";

// const baseUrl = "http://localhost:5000";
const baseUrl = "https://cvapis.unialsolutions.com";
export const serverImageUrl = "https://cvapis.unialsolutions.com/assets/";
export const authUrl = "https://cvapis.unialsolutions.com";

export const getHeroSection = () => axios.get(`${baseUrl}/herosection`);
export const insertHeroSection = (data) => axios.post(`${baseUrl}/herosection`, data);
export const updateHeroSection = (id, data) => axios.put(`${baseUrl}/herosection/${id}`, data);
export const deleteHeroSection = (id) => axios.delete(`${baseUrl}/herosection/${id}`);

export const getCvCategories = () => axios.get(`${baseUrl}/cvcategory`);
export const insertCvCategories = (data) => axios.post(`${baseUrl}/cvcategory`, data);
export const updateCvCategories = (id, data) => axios.put(`${baseUrl}/cvcategory/${id}`, data);
export const deleteCvCategories = (id) => axios.delete(`${baseUrl}/cvcategory/${id}`);

export const getCvSkills = () => axios.get(`${baseUrl}/cvskills`);
export const insertCvSkills = (data) => axios.post(`${baseUrl}/cvskills`, data);
export const updateCvSkills = (id, data) => axios.put(`${baseUrl}/cvskills/${id}`, data);
export const deleteCvSkills = (id) => axios.delete(`${baseUrl}/cvskills/${id}`);
export const getCvSkillsId = (id) => axios.get(`${baseUrl}/cvskills/category/${id}`);

export const getCvProjects = () => axios.get(`${baseUrl}/cvprojects`);
export const insertCvProjects = (data) => axios.post(`${baseUrl}/cvprojects`, data);
export const updateCvProjects = (id, data) => axios.put(`${baseUrl}/cvprojects/${id}`, data);
export const deleteCvProjects = (id) => axios.delete(`${baseUrl}/cvprojects/${id}`);

export const getCvProducts = () => axios.get(`${baseUrl}/cvproducts`);
export const insertCvProducts = (data) => axios.post(`${baseUrl}/cvproducts`, data);
export const updateCvProducts = (id, data) => axios.put(`${baseUrl}/cvproducts/${id}`, data);
export const deleteCvProducts = (id) => axios.delete(`${baseUrl}/cvproducts/${id}`);

export const getHomePageSEO = () => axios.get(`${baseUrl}/homepageseo`);
export const updateHomePageSEO = (id, data) => axios.put(`${baseUrl}/homepageseo/${id}`, data);
export const getSkillsPageSeo = () => axios.get(`${baseUrl}/skillspageseo`);
export const updateSkillsPageSeo = (id, data) => axios.put(`${baseUrl}/skillspageseo/${id}`, data);
export const getProjectPageSeo = () => axios.get(`${baseUrl}/projectpageseo`);
export const updateProjectPageSeo = (id, data) => axios.put(`${baseUrl}/projectpageseo/${id}`, data);
export const getProductPageSeo = () => axios.get(`${baseUrl}/productpageseo`);
export const updateProductPageSeo = (id, data) => axios.put(`${baseUrl}/productpageseo/${id}`, data);
export const getAboutPageSeo = () => axios.get(`${baseUrl}/aboutpageseo`);
export const updateAboutPageSeo = (id, data) => axios.put(`${baseUrl}/aboutpageseo/${id}`, data);
export const getContactPageSeo = () => axios.get(`${baseUrl}/contactpageseo`);
export const updateContactPageSeo = (id, data) => axios.put(`${baseUrl}/contactpageseo/${id}`, data);

export const getSkillsPageSection = () => axios.get(`${baseUrl}/skillspagesection`);
export const updateSkillsPageSection = (id, data) => axios.put(`${baseUrl}/skillspagesection/${id}`, data);
export const getProjectsPageSection = () => axios.get(`${baseUrl}/projectspagesection`);
export const updateProjectsPageSection = (id, data) => axios.put(`${baseUrl}/projectspagesection/${id}`, data);
export const getProductsPageSection = () => axios.get(`${baseUrl}/productspagesection`);
export const updateProductsPageSection = (id, data) => axios.put(`${baseUrl}/productspagesection/${id}`, data);

export const getFooterSection = () => axios.get(`${baseUrl}/footersection`);
export const updateFooterSection = (id, data) => axios.put(`${baseUrl}/footersection/${id}`, data);

export const getFooterLinks = () => axios.get(`${baseUrl}/footerlinks`);
export const insertFooterLinks = (data) => axios.post(`${baseUrl}/footerlinks`, data);
export const updateFooterLinks = (id, data) => axios.put(`${baseUrl}/footerlinks/${id}`, data);
export const deleteFooterLinks = (id) => axios.delete(`${baseUrl}/footerlinks/${id}`);

export const getCvFavicon = () => axios.get(`${baseUrl}/cvfavicon`);
export const updateCvFavicon = (id, data) => axios.put(`${baseUrl}/cvfavicon/${id}`, data);
