import React, { Fragment, useState, useEffect } from "react";
import { Breadcrumbs, H5, Image } from "../../../AbstractElements";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
} from "reactstrap";
import DataTable from "react-data-table-component";
import { useNavigate } from "react-router-dom";
import { deleteCvProjects, getCvProjects, serverImageUrl } from "../../../api/api";

export const style = {
  width: 40,
  height: 40,
};
export const style2 = { width: 60, fontSize: 14, padding: 4 };

function CvProjectsList() {
  const navigate = useNavigate();
  const [dataApi, setDataApi] = useState([]);

  const getData = async () => {
    const res = await getCvProjects();
    setDataApi([...res.data]);
  };

  const deleteListItem = async (id) => {
    const res = await deleteCvProjects(id);
    if (res.status === 200) {
      const freshArray = dataApi.filter((val) => val.projects_id !== id);
      setDataApi(freshArray);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const datalist = dataApi.map((elem) => {
    return {
      image: (
        <Image
          attrImage={{
            src: serverImageUrl + elem.projects_image,
            style: style,
            alt: "Unavailable",
          }}
        />
      ),

      Title: `${elem.projects_title}`,
      Text: elem.projects_texts,
      BtnText: elem.projects_btn,
      action: (
        <div>
          <span>
            <Button
              onClick={() => deleteListItem(elem.projects_id)}
              className="btn btn-danger btn-xs"
              style={style2}
            >
              Delete
            </Button>
          </span>{" "}
          &nbsp;&nbsp;
          <span>
            <Button
              onClick={() => {
                navigate(`${process.env.PUBLIC_URL}/project/add_project`, {
                  state: { dataObj: elem },
                });
              }}
              className="btn btn-success btn-xs"
              style={style2}
            >
              Edit{" "}
            </Button>
          </span>
        </div>
      ),
    };
  });

  const dataColumns = [
    {
      name: "Image",
      selector: (row) => row.image,
      sortable: true,
      center: true,
    },
    {
      name: "Title",
      selector: (row) => row.Title,
      sortable: true,
      center: true,
    },
    {
      name: "Text",
      selector: (row) => row.Text,
      sortable: true,
      center: true,
    },
    {
      name: "Button Text",
      selector: (row) => row.BtnText,
      sortable: true,
      center: true,
    },
    {
      name: "Action",
      selector: (row) => row.action,
      sortable: true,
      center: true,
    },
  ];

  return (
    <Fragment>
      <Breadcrumbs
        parent="Projects"
        title="Projects"
        mainTitle="Projects List"
      />
      <Container fluid={true}>
        <Row>
          <Col sm="12">
            <Card>
              <CardHeader className="pb-0">
                <H5>{"Projects List"}</H5>
              </CardHeader>
              <CardBody>
                <div className="table-responsive product-table">
                  <DataTable
                    noHeader
                    pagination
                    paginationServer
                    columns={dataColumns}
                    data={datalist}
                  />
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default CvProjectsList;
